/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
/* stylelint-disable  declaration-no-important */
@import '../../../scss/helpers/all';

$right-width: 5.2rem;
$badge-width: 2.2rem;
$badge-font-size: 1.2rem;
$shadow-rgba: rgba(0, 0, 0, .03);

@mixin Accordion-right {
  align-self: stretch;
  min-width: $right-width;
  z-index: 2;
}

.Accordion__badge {
  border: $border-width-sm solid;
  border-radius: $fn-border-radius-full;
  display: inline-block;
  font-size: $badge-font-size;
  height: $badge-width;
  margin: 0 $spacing-xs;
  text-align: center;
  width: $badge-width;
}

.Accordion__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
};

.Accordion__container--borderTop {
  border-top: $border-width-sm solid $color-gray-50;
}

.Accordion__container--borderBottom {
  border-bottom: $border-width-sm solid $color-gray-50;
}

.Accordion__labelContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 0;
}

.Accordion__labelContent--header {
  margin-right: $spacing-sm;
}

.Accordion__labelIcon {
  margin-right: $spacing-sm;
}

.Accordion__labelIconRight {
  margin-left: $spacing-sm;
}

.Accordion__labelContent--subHeading {
  color: $text-muted;
  margin: 0;
}

.Accordion__labelMiddleContent--header {
  display: block;
  margin: auto;
}

.Accordion__content {
  align-self: center;
  flex-grow: 1;
  padding: $spacing-md;
  padding-right: 0;
};

.Accordion__label {
  align-self: center;
  flex-grow: 1;
  word-break: break-all;
};

.Accordion__value {
  align-self: center;
  flex-grow: 1;
  font-weight: bold;
  text-align: right;
};

.Accordion--default {
  background-color: inherit;
}

.Accordion__header--info {
  background-color: $color-blue-20;
  position: relative;
}

.Accordion--hover {
  &:hover {
    background: rgba(0, 0, 0, .14);
    border-color: transparent;
    cursor: pointer;
  }
}

.Accordion--clip {
  &::after {
    border-bottom-color: white;
    border-left: $spacing-sm solid transparent;
    border-right: $spacing-sm solid transparent;
    border-top: 1rem solid $color-white;
    content: '';
    height: 0;
    left: 0;
    margin-left: $spacing-md;
    margin-top: -$spacing-2-xs;
    position: absolute;
    top: 0;
    width: 0;
  }
}

.Accordion__header--warning {
  background-color: $color-yellow-20;
  position: relative;
}

.Accordion__header--critical {
  background-color: $color-red-20;
  position: relative;
}

.Accordion__value--default {
  color: $color-gray-100;
}

.Accordion__value--strikethrough {
  color: $color-gray-100;
  text-decoration: line-through;
}

.Accordion__action {
  @include Accordion-right();
  align-items: center;
  display: flex;
  justify-content: center;

  & > Button {
    color: $color-gray-80;

    &:hover:not([disabled]) {
      background: rgba(0, 0, 0, .07);
      border-color: transparent;
    }

    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:visited:not([disabled]) {
      //   background: rgba(0, 0, 0, .07);
      border-color: transparent;
    }

    span {
      margin-right: 0;
    }
  }
};

.Accordion__expander {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: $spacing-sm;
};

.Icon {
  color: $color-indigo-60;
};


.AccordionContentContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.AccordionContentBody {
  flex-grow: 1;
  margin-left: $spacing-md;
  margin-right: 0;
}

.AccordionContentBody---fullWidth {
  margin-left: 0;
  margin-right: 0;
}

.AccordionContainerContainer__gutter {
  @include Accordion-right();
  display: flex;
}
