/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
.UserMap {
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}

.MapContainer {
  height: 100%;
}

.DrawerListItem {
  margin-left: -3.2rem;
  margin-right: -2.4rem;
}

.tableContainer {
  padding-top: 1.6rem;
}

.table-header-bordered thead th {
  background: #fff;
  border: 1px solid #dee2e7;
  margin-top: 1.6rem;
}

.table-header-bordered tbody {
  background: #fff;
  border: 1px solid #dee2e7;
}

.table--sortIcon {
  color: #636b88;
}

.table--enableColumn {
  text-align: center;
}
