@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.formContainer {
  width: 100%;

  &.addMode {
    background-color: $color-gray-30;
    border-radius: $spacing-xs;
    padding: $spacing-md;
  }

  &.editMode {
    background-color: $color-white;
    border-radius: $spacing-xs;
    padding: $spacing-md;
  }
}

.formLayout {
  display: flex;
  width: 100%;
}

.formContent {
  flex: 1;
  margin-right: $spacing-md;
}

.formContentEditMode {
  flex: 1;
}

.deleteSection {
  flex-shrink: 0;
  width: 24px;
}

.deleteButton {
  background: transparent;
  color: $color-gray-80;
  margin: 0;
  padding: 0;

  &:hover {
    background: transparent;
  }
}

.formField {
  margin-bottom: $spacing-sm;
}

.sectionTitle {
  color: $color-gray-100;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: $spacing-md;
  margin-top: $spacing-md;
}

.divider {
  border: 0;
  border-top: 1px solid $color-gray-50;
  width: 100%;
}

.otherLocationInfo {
  margin-bottom: $spacing-md;
}
