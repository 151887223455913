@import "../../../../scss/helpers/all";

.PricingTableComponent {
  margin-top: $spacing-md;
}

.PricingTableComponent__linkText {
  font-size: $text-font-size-sm;
}

.PricingTableComponent__additionalFee {
  color: $color-gray-70;
  font-size: $text-font-size-xs;
}

.MarketSmartInsightsModal__iframeContainer {
  position: relative;
  width: 100%;
}

.MarketSmartInsightsModal__iframeContainer iframe {
  left: 0;
  top: 0;
  width: 100%;
}

.MarketSmartInsightsModal__section--margin {
  margin-top: $spacing-md;
}

@function reverse($spacing) {
  @return ($spacing * -1);
};

.iframe-wrapper {
  margin: reverse($spacing-md) reverse($spacing-md) 0 reverse($spacing-md);
}
