@import '../../../../scss/helpers/all';

.image--content--valid {
  display: block;
  height: auto;
  margin-right: auto;
  max-height: 80px;
  max-width: 240px;
  width: auto;
}

.image--content--invalid {
  display: block;
  height: auto;
  margin-right: auto;
  max-height: 80px;
  max-width: 240px;
  width: auto;
}

.image--text {
  color: $color-gray-100;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &--empty {
    color: $color-gray-80;
    font-style: italic;
  }
}
