@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.EntryForm {
  padding-bottom: $spacing-md;
}

.EinLabel {
  color: $color-gray-80;
  font-family: Open Sans SemiBold, helvetica, sans-serif;
  font-weight: 600;
}
