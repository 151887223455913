@import '../../../../scss/helpers/_all';

$container-max-width: 1100px;

.container {
  display: block;
  margin-bottom: $spacing-lg;
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width !important; // stylelint-disable-line
}
