@import '../../../../scss/helpers/_all.scss';
@import '../../../Field-helpers';

.currentpackage-main,
.currentpackage-main > div {
  border-radius: $spacing-sm;
  height: 100%;
}

.cp-details {
  border-radius: $spacing-sm;
  margin-top: $spacing-lg;
  padding: $spacing-md;
}

.cp-tile-content {
  min-height: 100%;
}

.bg-teal {
  background-color: $color-teal-20;
}

.bg-blue {
  background-color: $color-blue-20;
}

.bg-indigo {
  background-color: $color-indigo-20;
}

.bg-red {
  background-color: $color-red-20;
}

.cp-name {
  margin-bottom: $spacing-sm;
}

.cp-footer {
  font-size: $text-font-size-sm;
  padding: $spacing-lg $spacing-md $spacing-sm 0;
}
