@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.TextArea {
  @include Field-input-base();
  height: 13.6rem;
}

.TextArea--customize {
  background: $color-orange-60;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  border-radius: $fn-border-radius-md 5rem;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-width: medium 1px 1px medium;
  color: $color-white;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin-bottom: $spacing-md;
  padding: $spacing-md $spacing-md;
  width: 100%;
}
