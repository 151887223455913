@import '../../../../scss/helpers/_all';

@function reverse($spacing) {
  @return ($spacing * -1);
}

@mixin reverse-margin {
  margin: reverse($spacing-md);
}

.profile-info-items-container {
  @include reverse-margin;

  .profile-info-item {
    padding: $spacing-lg;
  }

  .disabled-text {
    color: $color-gray-70;
    display: block;
    font-size: $text-font-size-xs;
  }

  .edit {
    &--disabled--content {
      padding-right: $spacing-md;
      padding-top: $spacing-md;
    }

    &--button {
      color: $color-gray-70;
      display: flex;
      justify-content: flex-end;
      margin-bottom: reverse($spacing-md);
      margin-top: reverse($spacing-md);
      padding-top: 2px; /* stylelint-disable-line declaration-property-unit-blacklist */

      &--icon {
        border-radius: 50%;
        color: $color-gray-70;
        height: 50px; /* stylelint-disable-line declaration-property-unit-blacklist */
        width: 50px; /* stylelint-disable-line declaration-property-unit-blacklist */

        span {
          margin-left: -(calc($spacing-2-xs / 2));
        }
      }
    }
  }

  .edit-form-action-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: $spacing-md $spacing-md 0 0;
  }

  .info-box-content {
    background-color: $color-gray-20;
    border-radius: $fn-border-radius-sm;
    margin-right: 7.5%;
    margin-top: $spacing-md;
    padding: $spacing-md;

    &--warning {
      @extend .info-box-content;
      background-color: $color-yellow-20;
      margin-right: 0;
    }

    .info-box-message {
      margin-left: $spacing-md;
    }
  }

  @media only screen and (max-width: 900px) {
    .info-box-content {
      margin-right: 0;
    }
  }

  .optional-text {
    color: $color-gray-80;
    font-size: $text-font-size-sm;
    font-style: italic;
  }

  .invalid-help-message-container {
    color: $color-red-100;
    padding: $spacing-xs;

    .invalid-help-message {
      margin-left: $spacing-sm;
    }
  }
}
