@import '../../scss/helpers/_all.scss';

.searchWrapper {
  .search {
    background-color: $color-gray-50;
    border-radius: $spacing-xs;
    border-width: $border-width-sm;
    height: $spacing-3-xl;
    padding: $border-width-sm;
  }

  .separator {
    border-bottom: $border-width-sm solid $color-gray-50;
    margin-top: $text-font-size-md;
  }
}

.errorWrapper {
  margin-top: $text-font-size-sm;

  .errorMessage {
    color: $color-red-100;
    font-size: $text-font-size-xs;
    font-style: normal;
    font-weight: 400;
    line-height: $text-line-height-xxxs;
  }
}

.groupListsWrapper {
  .listsBody {
    max-height: 50rem;
    overflow: scroll;

    .group {
      margin-top: $spacing-sm;

      .groupName {
        color: $color-gray-80;
        padding: $spacing-sm $text-font-size-md;
      }
    }

    .listItem {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: $spacing-sm $text-font-size-md;

      &.activeItem {
        background-color: $color-indigo-20;
      }

      &:hover {
        background-color: $color-indigo-20;
      }

      .listIcon {
        color: $color-indigo-60;
      }

      .listContent {
        .listName {
          font-size: $text-font-size-sm;
          font-weight: 600;
          line-height: $text-line-height-xxs;
        }

        .listType {
          align-items: center;
          color: $color-gray-80;
          display: flex;
          font-size: $text-font-size-xs;
          line-height: $text-line-height-xxxs;
        }
      }
    }
  }
}
