@import '../../../scss/helpers/all';

.RequirementsTableWrapper {
  margin: 0 -$spacing-md 0;
}

table.RequirementsTable thead th {
  border-color: $color-gray-50;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-width: 1px;
}

table.RequirementsTable thead th.LeftPadded,
table.RequirementsTable tbody td.LeftPadded {
  padding-left: $spacing-md;
}

table.RequirementsTable thead th.RightPadded,
table.RequirementsTable tbody td.RightPadded {
  padding-right: $spacing-md;
}

table.RequirementsTable tbody td {
  border: 0;
}

table.RequirementsTable thead th.Center,
table.RequirementsTable tbody td.Center {
  text-align: center;
}

table.RequirementsTable thead th.Right,
table.RequirementsTable tbody td.Right {
  text-align: right;
}

table.RequirementsTable thead th.Narrow {
  width: 13rem;
}

.PaddedChip {
  display: inline-block;
  margin: 0 $spacing-2-xs $spacing-2-xs;
}

table.RequirementsTable button {
  background: initial;
  border: 0;
}
