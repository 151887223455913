@import '../../scss/helpers/_all.scss';

.Body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Body::-webkit-scrollbar {
  width: 0;
}

.GroupTitle {
  color: $color-gray-80;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: $spacing-md;
}

.GroupMessage {
  margin-bottom: $spacing-md;
  margin-left: $spacing-sm;
}

.Reply {
  margin-left: $spacing-xl;
  margin-top: $spacing-sm;
}

.ReplyBox {
  margin-left: $spacing-sm;
}

.SingleReply {
  color: $color-gray-80;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .15px;
  margin-left: $spacing-xs;
  margin-top: $spacing-sm;
}

.GroupMessage {
  margin-bottom: $spacing-md;
  margin-left: $spacing-sm;
}

.SingleReplyTextInThread {
  margin-left: $spacing-md * 1.9;
}

.ThreadInputBox {
  margin-left: $spacing-sm;
  margin-top: $spacing-sm;
}

.User {
  display: flex;
  float: left;
  height: 100%;
}

.GroupThumb {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  flex-shrink: 0;
  height: 2rem;
  margin-left: -$spacing-md;
  margin-right: $spacing-sm;
  margin-top: $spacing-xs;
  vertical-align: middle;
  width: 2rem;
}

.GroupMargin {
  color: $color-indigo-60;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .15px;
  margin-left: $spacing-md * 1.5;
}

.ButtonGroup {
  border-bottom: 1px solid $color-gray-40;
  padding-bottom: $spacing-sm;
}

.MessageText {
  border-bottom: 1px solid $color-gray-40;
  height: 60px;
  margin-bottom: $spacing-sm;
}

.MessageID {
  color: $color-gray-80;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: .15px;
}

.MessageList {
  margin-left: $spacing-sm;
  margin-right: $spacing-sm;
  margin-top: $spacing-md;
  -ms-overflow-style: none;
  overflow-y: scroll;
  scrollbar-width: none;
}

.MessageListForDrawer {
  margin-bottom: $spacing-xxl;
  margin-left: $spacing-sm;
  margin-right: $spacing-sm;
  margin-top: $spacing-md;
  max-width: 440px;
  overflow-wrap: anywhere;
  overflow-y: hidden;
  @media (max-width: 768px) {
    max-width: 303px;
  }
}

.MessageListForDrawerWithCounter {
  margin-bottom: ($spacing-xxl * 1.5);
  margin-left: $spacing-sm;
  margin-right: $spacing-sm;
  margin-top: $spacing-md;
  max-width: 440px;
  overflow-wrap: anywhere;
  overflow-y: hidden;
  @media (max-width: 768px) {
    max-width: 303px;
  }
}

.MessageList::-webkit-scrollbar {
  width: 0;
}

.MessageInputBox {
  margin-left: $spacing-sm;
  margin-right: $spacing-sm;
}

.FixedMessageInputBox {
  background-color: $color-white;
  bottom: 0;
  position: fixed;
  width: 440px;
  @media (max-width: 768px) {
    width: 303px;
  }
}

.FixedMessageInputBoxPad {
  padding-bottom: $spacing-md;
}

.AttachmentFileTypeIndicator {
  color: $color-gray-80;
  display: flex;
  flex-direction: row-reverse;
  font-size: $spacing-sm * 1.5;
  margin-top: $spacing-xs;
  padding-bottom: $spacing-xs;
  padding-right: $spacing-xs * .5;
  text-align: right;
}

.AttachmentFileTypeIndicator p {
  margin-bottom: 0;
}

.AttachmentFileTypeIndicatorIcon {
  padding-right: $spacing-xs * .5;
  padding-top: $spacing-xs * .5;
}

.ContentInsideDiv {
  hyphens: auto;
  overflow-wrap: anywhere;
}
