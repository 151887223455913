/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
/* stylelint-disable  declaration-no-important */
.JobSummary {
  margin: 1.6rem;
}

.JobSummaryTableContainer {
  margin-top: 1rem;
  max-height: 55vh;
  overflow-y: auto;
}

.JobSummary tbody td {
  white-space: break-spaces !important;
}

.JobSummary tbody td small {
  color: #666;
  display: block;
}
