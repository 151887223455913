/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
.job-history-modal-wrapper {
  display: flex;
  margin: -1.6rem;
}

.job-history-modal-left,
.job-history-modal-right {
  align-self: stretch;
  min-height: 70vh;
}

.job-history-modal-wrapper.has-filters-contract .job-history-modal-left {
  position: fixed;
}

.job-history-modal-wrapper.has-filters-contract .job-history-modal-right {
  border-left: 1px solid #c4cad4;
  margin-left: 27rem;
}

.job-history-modal-left {
  flex-grow: 2;
  height: auto;
  max-width: 27rem;
  min-width: 27rem;
  padding: 1.6rem;
}

.job-history-modal-right {
  flex-grow: 5;
}

.rating-filter {
  border-radius: 1.5rem;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 .5rem;
}

.rating-filter:hover {
  background-color: #dee2e7
}

.rating-filter-active {
  background-color: #dee2e7
}

.rating-filter div {
  align-self: center;
}

.rating-filter div:nth-child(1) {
  flex-grow: 0;
}

.rating-filter div:nth-child(2) {
  flex-grow: 1;
  margin: 0 .8rem;
}

.rating-filter div:nth-child(3) {
  flex-grow: 0;
}

.rating-tile {
  border-bottom: 1px solid #c4cad4;
  padding: 1.6rem;
}

.rating-tile:last-child {
  border-bottom: 0;
}

.rating-icons {
  display: flex;
}

.rating-icon {
  color: #626b89;
  margin-right: .4rem;
}

.rating-heading {
  word-break: break-word;
}

.rating-title {
  margin-right: 1.6rem;
}

.rating-icon,
.rating-title {
  align-self: bottom;
}

.rating-pqap {
  white-space: nowrap;
}

.rating-pqap span {
  color: #a51d2b;
}

.rating-pqap small {
  position: relative;
  top: -.5rem;
}

.rating-status {
  margin-left: auto;
}

.comment-container {
  background: #f2f4f8;
  display: flex;
}

.comment-thumbnail {
  flex-grow: 0;
  width: 5rem;
}

.comment-content {
  flex-grow: 1;
}

.comment-info {
  align-items: center;
  display: flex;
  margin-top: .4rem;
}

.comment-info .comment-action {
  margin-left: 1.6rem;
}

.comment-info .comment-action:first-child {
  margin-left: 0;
}

.comment-action small {
  margin-left: .4rem;
  position: relative;
  top: -.5rem;
}
