/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
.reliability-container {
  margin-left: -3.2rem;
  margin-right: -2.2rem;
}

.reliability-score-items {
  padding-left: 1.6rem;
  padding-right: 4rem;
}

.reliability-sub-text {
  font-size: 1.2rem;
  letter-spacing: 0;
  margin-top: -.6rem;
}

.text-semi-bold {
  font-family: Open Sans Bold, helvetica, sans-sarif, serif;
}

.show-reliability-details {
  background: no-repeat;
  border: none;
  color: #4051BF;
  cursor: pointer;
  display: block;
  padding: 0;
}

.flex-vertical-center {
  align-items: center;
  display: flex;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.r-body-title {
  font-weight: bold;
  margin-bottom: 3px;
}

.r-body-sub-text {
  margin-right: 5px;
}

.r-inline-count-wrapper {
  align-items: center;
  display: flex;
}

.r-inline-count-number {
  font-size: 32px;
  line-height: 1;
}

.r-inline-count-label {
  margin-left: 5px;
}

.r-text-smaller {
  font-size: 12px;
}

.r-spacing-xs {
  margin-bottom: 3px;
}

.r-modal-container {
  padding: 4px;
}
