@import '../../../../scss/helpers/_all.scss';
@import '../../../Field-helpers';

.SubscriptionCard--main,
.SubscriptionCard--main > div {
  border-radius: $spacing-sm;
}

.SubscriptionCard--subHeading {
  margin-right: $spacing-sm;
}

.SubscriptionCard--subHeading span {
  font-size: $text-font-size-md;
}

.SubscriptionCard--subHeading a {
  font-size: $text-font-size-sm;
}

.SubscriptionCard--gaugeBar_container {
  padding-bottom: $spacing-sm;
  padding-top: $spacing-sm;
}

.SubscriptionCard--footer_container {
  padding-bottom: $spacing-sm;
  padding-top: $spacing-md;
}

.SubscriptionCard--footer_container span {
  font-size: $text-font-size-md;
}
