@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.note-container {
  background-color: $color-gray-30;
  border-radius: 4px;
  display: flex;
  margin-bottom: $spacing-sm;
  padding: $spacing-sm;
}

.add-note-container {
  background-color: $color-gray-30;
  border-radius: 4px;
  margin-bottom: $spacing-sm;
  padding: $spacing-md;
}

.note-text-container {
  flex: 1;
}

.note-text {
  word-wrap: break-word;
}

.helper-text {
  color: $color-gray-70;
  font-size: $text-font-size-xs;
  font-style: italic;
  margin-top: $spacing-xs;
}

.add-note-footer {
  margin-top: $spacing-sm;
}

.note-header {
  color: $color-gray-70;
  font-size: $text-font-size-xs;
  line-height: $text-line-height-xs;
  margin-bottom: 0;
}

.note-delete {
  margin-right: -$spacing-xs;
  margin-top: -$spacing-xs;
}

.note-text-expandable {
  display: inline;
  margin-bottom: $spacing-xs;
  word-break: break-word;
}

.note-button-container {
  align-items: flex-end;
  display: flex;
}

.note-button-expandable {
  min-width: fit-content;
}

.margin-vertical--sm {
  margin-bottom: $spacing-sm;
  margin-top: $spacing-sm;
}

.comment-thumbnail {
  flex-grow: 0;
  margin-right: $spacing-sm;
  width: 4rem;
}

.note-footer {
  color: $color-gray-70;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 0;
}

.note-alignment {
  align-items: center;
  display: flex;
  margin-top: -$text-font-size-sm;
}

.note-alignment button {
  padding-bottom: 0;
  padding-left: 0;
  width: 27px;
}
