@import '../../../../scss/helpers/_all.scss';
@import '../../../Field-helpers';

$side-margin: 17.85vw;

.account-overview-content {
  margin-left: $side-margin;
  margin-right: $side-margin;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .account-overview-content {
    margin-left: 0;
    margin-right: 0;
  }
}
