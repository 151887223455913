/* stylelint-disable  declaration-property-unit-blacklist */
@import '../../../scss/helpers/_all.scss';

.NoWorkHistory {
  padding: 1.6rem;
  text-align: center;
}

.AccordionV4 {
  margin-right: 2rem;
  text-align: right;
  width: 2rem;
}

.ExpandedContent {
  margin-bottom: .5rem;
}

.WorkSummaryContainer {
  padding: 1.5rem;
}

.ProjectType {
  align-items: center;
  background: $color-gray-10;
  border-top: 1px solid $color-gray-50;
  display: flex;
  gap: .5rem;
  justify-content: space-between;
  padding: 1rem;
}

.ProjectTypeName {
  color: $color-gray-100;
  font-family: "Open Sans";
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: .3rem;
}

.ProjectTypeLeftContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ProjectTypeRightContent {
  align-items: center;
  display: flex;
}

.ProjectTypeNumJobs {
  // color: $color-indigo-60; will be implemented after job history modal update
  color: $color-gray-100;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-left: auto;
  white-space: nowrap;
}

.ProjectTypeNumJobsDisabled {
  color: $color-gray-50;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: auto;
  white-space: nowrap;
}

.HourlyRate {
  color: $color-gray-80;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.124rem;
  white-space: nowrap;
}

.SkillSet {
  align-items: center;
  display: flex;
  gap: .5rem;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.SkillSetName {
  color: $color-gray-100;
  font-family: "Open Sans";
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.SkillSetLeftContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  justify-content: flex-start;
}

.SkillSetLeftContent>div {
  align-items: center;
  display: flex;
  gap: .5rem;
}

.SkillSetRightContent {
  align-items: center;
  display: flex;
}

.SkillSetNumJobs {
  // color: $color-indigo-60; will be implemented after job history modal update
  color: $color-gray-100;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-left: auto;
}

.SkillSetLastCompletion {
  color: $color-gray-80;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.124rem;
}

.SKMarketplace {
  color: $color-yellow-60;
}

.SKMyCompany {
  color: $color-green-60;
}

.SkillSetRatingText {
  color: $color-gray-100;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  margin-top: 4px;
  text-align: center;
}

.SkillSetNonRatingText {
  color: $color-gray-70;
  font-family: "Open Sans";
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: 1.125rem;
  text-align: center;
}

.SKMarketplace,
.SKMyCompany {
  align-items: center;
  display: flex;
  gap: 2px;
}

.SKNoRating {
  align-items: center;
  color: $color-gray-50;
  display: flex;
  gap: 2px;
}

.SkillV4 {
  align-items: center;
  display: flex;
  gap: .5rem;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.SkillV4LeftContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  justify-content: flex-start;
  margin-left: 1rem;
}

.SkillV4LeftContent>div {
  align-items: center;
  display: flex;
  gap: .5rem;
}

.SkillV4RightContent {
  align-items: center;
  display: flex;
}

.SkillV4NumJobs {
  // color: $color-indigo-60; //will be implemented after job history modal update
  color: $color-gray-100;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2rem;
  margin-left: auto;
  margin-right: 3.8rem;
}


