@import '../../scss/helpers/_all.scss';

.TotalContent {
  background-color: $color-gray-10;
  margin-top: $spacing-sm;
  padding: $spacing-md $spacing-md;
}

.InfoArea {
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    display: block;
  }
}

.InfoAreaSM {
  display: block;
  width: 100%;
}

.WorkOrderIndo {
  width: 25%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.WorkOrderIndoSM {
  width: 100%;
}

.FeedbackInfo {
  border-left: 1px solid $color-gray-40;
  margin-top: $spacing-xs;
  padding-left: $spacing-md;
  width: 75%;
  @media (max-width: 768px) {
    border-left: none;
    margin-top: $spacing-xs;
    padding-left: 0;
    width: 100%;
  }
}

.FeedbackInfoSM {
  margin-top: $spacing-xs;
  width: 100%;
}

.ManagerInfo {
  background-color: $color-gray-20;
  margin-left: -$spacing-md;
  margin-right: -$spacing-md;
  margin-top: $spacing-md;
  padding-bottom: $spacing-sm * 1.5;
  padding-left: $spacing-md;
  padding-top: $spacing-sm * 1.5;
}

.WorkOrderTitle {
  color: $color-gray-100;
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-top: $spacing-xs;
}

.WorkOrderTitleSM {
  border-left: 1px solid $color-gray-90;
  color: $color-gray-100;
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-left: $spacing-sm;
  padding-left: $spacing-sm;
}

.ContentArea {
  flex: 1;
  height: 100%;
  margin: auto $spacing-sm;
}

.Dispatcher {
  color: $color-gray-80;
  font-size: 12px;
  font-weight: 600;
}

.DateTime {
  color: $color-gray-80;
  font-size: 12px;
  font-weight: 400;
}

.Info {
  align-items: center;
  display: flex;
}

.InfoContent {
  display: flex;
}

.Score {
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}

.WOLink {
  color: $color-indigo-60;
  font-size: 12px;
  font-weight: 400;
  margin-left: $spacing-md;
  white-space: nowrap;
}

.Comment {
  color: $color-gray-90;
  font-size: 14px;
  font-weight: 400;
}

.Pagination {
  display: flex;
  justify-content: end;
  margin-top: $spacing-sm;
}

.EmptyContent {
  color: $color-gray-60;
  font-size: 14px;
  margin-bottom: $spacing-lg;
  margin-left: $spacing-lg;
  margin-top: $spacing-xs;
}

.NoComment {
  color: $color-gray-60;
  font-size: 14px;
  font-style: italic;
  margin-top: $spacing-xs;
}

.Icon {
  color: $color-gray-100;
}

.AttributeContent {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacing-xs;
}

.ClientInfo {
  align-items: center;
  display: flex;
  margin-top: $spacing-sm;
}

.InfoTitle {
  color: $color-gray-60;
  font-size: 12px;
  font-weight: 600;
  margin-right: $spacing-xs;
}

.Client {
  color: $color-gray-100;
  font-size: 12px;
  font-weight: 400;
}

.WorkTypeArea {
  display: flex;
  flex-wrap: wrap;
}

.TypeOfWork {
  background-color: $color-gray-30;
  border-radius: 2px;
  color: $color-gray-100;
  font-size: 12px;
  font-weight: 400;
  margin-right: $spacing-xs;
  margin-top: $spacing-xs;
  padding: $spacing-xs $spacing-xs;
}

.TypeTitle {
  color: $color-gray-60;
  font-size: 12px;
  font-weight: 600;
  margin-right: $spacing-xs;
  margin-top: $spacing-sm;
}

.ChipWrapper {
  margin: $spacing-xs;
}

.NewPage {
  color: $color-indigo-60;
  cursor: pointer;
  display: flex;
  justify-content: end;
  margin-bottom: $spacing-md;
  margin-top: $spacing-md;
}

.NewPageText {
  margin-right: $spacing-sm;
}
