/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
.TechnicianInfo {
  align-items: center;
  display: flex;
}

.TechnicianInfo > :first-child {
  margin-right: 2rem;
}

.LinkLine {
  align-items: baseline;
  display: flex;
}

.LinkLine small {
  color: #666666;
}

.LinkLine a {
  font-size: 1.8rem;
  margin-right: 1rem;
}

.RatingLine {
  align-items: center;
  display: flex;
}

.RatingLine svg {
  color: #636b88;
}

.RatingLine i {
  color: #576376;
  font-size: 1.2rem;
  font-style: normal;
  padding-left: .25rem;
  position: relative;
  top: -.17rem;
}

.RatingLine i.icon {
  top: -.3rem;
}

.RatingLine > * {
  margin-right: 1rem;
}

.RatingContent :first-child {
  margin-right: .5rem;
}
