/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
.star-container hr {
  margin: 0 -1.6rem 1.6rem;
}

.stars {
  text-align: center;
}

.star-count {
  font-size: 1.8rem;
}

.star-count-margin {
  margin-top: -1rem;
}

.star-type {
  font-size: 1.4rem;
}

.sub-list {
  list-style: none;
  padding: 0;
}

.sub-list .list-title {
  font-weight: 600;
}

.sub-list li {
  margin-bottom: 1.6rem;
  overflow: hidden;
}

.sub-list li > span {
  float: right;
}

.sub-list li > div {
  float: right;
  width: 20rem;
}

.sub-list li > div > div {
  display: inline-block;
  font-size: 90%;
  font-weight: 100;
  text-align: right;
  width: 10rem;
}

.commitment-content {
  background-color: #edeff3;
  border-radius: 4px;
  padding: 10px;
}

.commitment-percent {
  font-size: 2rem;
}

.commitment-text {
  font-style: italic;
}

.commitment-sub-list {
  list-style: none;
  padding: 0;
}

.commitment-sub-list .list-title {
  font-weight: 600;
}

.commitment-sub-list li {
  margin-bottom: 1.6rem;
  overflow: hidden;
}

.commitment-sub-list li > div {
  float: right;
  width: 20rem;
}

.commitment-sub-list li > div > div {
  display: inline-block;
  font-size: 90%;
  font-weight: 100;
  text-align: right;
  width: 10rem;
}

.performance-tile {
  color: #454e6e;
  display: flex;
  font-family: Open Sans, helvetica, sans-sarif;
  font-size: 2.2rem;
  letter-spacing: 0;
  line-height: 2.8rem;
  padding-bottom: 1.6rem;
}

button.see-commitment-link {
  background: no-repeat;
  border: none;
  color: #4051bf;
  cursor: pointer;
  display: block;
  padding: 0;
}

.timeliness-wrapper {
  margin-left: -3.2rem;
  margin-right: -2.2rem;
}

.timeliness-sub-text {
  font-size: 1.2rem;
  letter-spacing: 0;
  margin-top: -.6rem;
}

.font-size-xs {
  font-size: 1.2rem;
}

.commitment-container {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.reliability-tile {
  color: #454e6e;
  display: flex;
  font-family: Open Sans, helvetica, sans-sarif, serif;
  font-size: 2.2rem;
  letter-spacing: 0;
  line-height: 2.8rem;
  padding-bottom: 1.2rem;
}

.reliability-sub-text {
  margin-left: 12rem;
  margin-top: -3.2rem;
  position: relative;
}

.reliability-chip {
  align-items: center;
  background-color: #e6f9f1;
  border-radius: 1.6rem;
  display: flex;
  font-family: Open Sans Bold, helvetica, sans-sarif, serif;
  font-size: 1.2rem;
  max-width: 14rem;
  padding: .2rem;
}

.timeliness-chip {
  align-items: center;
  background-color: #e6f9f1;
  border-radius: 1.6rem;
  display: flex;
  font-family: Open Sans Bold, helvetica, sans-sarif, serif;
  font-size: 1.2rem;
  max-width: 7rem;
  padding: .2rem;
}

.semi-bold {
  font-family: Open Sans Bold, helvetica, sans-sarif, serif;
}

.reliability-chip-drawer {
  align-items: center;
  background-color: #e6f9f1;
  border-radius: 1.6rem;
  font-family: Open Sans Bold, helvetica, sans-sarif, serif;
  font-size: 1rem;
  max-width: 14rem;
  padding-top: .8rem;
}

.ranking-wrapper {
  margin-left: -3.2rem;
  width: 392px;
}

.Rating {
  color: #666666;
  font-family: Open Sans SemiBold, helvetica, sans-serif;
  padding-left: .4rem;
  text-align: right;
}

.Rating strong {
  color: initial;
}

.Rating em {
  color: #97a2b4;
  font-style: normal;
}

.Rating > div:first-child {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.Rating small {
  color: #747577;
  display: block;
  font-family: Open Sans SemiBold, helvetica, sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

.Rating .Unrated {
  color: #97a2b4;
}

.Rating .Marketplace {
  color: #e6910a;
}

.Rating .MyCompany {
  color: #085e38;
}

.RatingText {
  position: relative;
  top: -.16rem;
}
