@import '../../../../scss/helpers/all';

.invalid--validation {
  color: $color-red-100;
  padding: $spacing-xs;

  &--content {
    margin-left: $spacing-sm;
  }
}
