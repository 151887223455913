@import '../../../../scss/helpers/_all.scss';
@import '../../../Field-helpers';

.padding-md {
  padding: $spacing-md;
}

.message-modal-action {
  justify-content: center;
  padding-bottom: $spacing-md;
  padding-top: $spacing-md;
}
