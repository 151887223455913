@import '../../../scss/helpers/_all.scss';
@import '../../_Field-helpers';

.errorWrapper {
  position: relative;

  svg,
  svg path,
  svg g,
  svg * {
    color: $color-red-100;
    fill: $color-red-100;
    stroke: $color-red-100;
  }

  span {
    color: $color-red-100;
  }
}

.iconWrapper {
  left: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.messageText {
  color: $color-red-100;
  display: block;
  line-height: 1.5;
}
