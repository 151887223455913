@import '../../../../scss/helpers/all';

.action--bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding: $spacing-md $spacing-md 0 0;
}
