@import '../../../../scss/helpers/_all.scss';

$password-confirmation-tile-width: 40rem;

.password-confirmation-container {
  max-width: $password-confirmation-tile-width;

  .confirmation-text {
    padding-bottom: $spacing-md;
  }

  .password-submit {
    padding-top: $spacing-md;
  }

  .confirmation-help-text {
    color: $color-red-100;
    margin-top: $spacing-sm;
  }
}
