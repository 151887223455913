@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.snapshot-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.snapshot-sticky-container {
  overflow-y: hidden;
}

.snapshot-sticky {
  overflow-y: scroll;
}

.snapshot-footer {
  border-bottom: 1px solid rgb(196, 202, 212);
}

.snapshot-inner-container {
  flex: 1;
  overflow-x: hidden;
}

.snapshot-loader {
  height: 100%;
  width: 100%;
}
