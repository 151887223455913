/* stylelint-disable  declaration-property-unit-disallowed-list */
/* stylelint-disable  declaration-property-unit-blacklist */

@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.Chip__Outer {
  display: inline-block;
}

.Chip {
  align-items: center;
  background-color: $color-orange-20;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: .35rem;
  padding-left: .4rem;
  padding-right: .4rem;
}

.Chip__Icon {
  margin-right: 5px;
}

.Square__Icon {
  background-color: $color-orange-60;
  border-radius: 4px;
  margin-right: 10px;
  padding: .5rem;
}

.Chip__Label {
  font-family: Open Sans, helvetica, sans-serif;
  font-size: 1.25rem;
  margin-bottom: -2px;
  pointer-events: none;
  vertical-align: bottom;
  white-space: nowrap;
}

.Chip__Label--sm {
  font-size: 1.1rem;
}

.Label__Gray {
  color: $color-gray-90;
}

.Title__Container {
  align-items: center;
  display: flex;
}

.Title {
  font-family: Open Sans Bold, helvetica, sans-serif;
  font-size: 1.6rem;
}

.Subtitle {
  font-family: Open Sans Semi Bold, helvetica, sans-serif;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.Footer__Text {
  color: $color-gray-80;
  font-family: Open Sans, helvetica, sans-serif;
  font-size: 1.05rem;
  letter-spacing: .3px;
  line-height: 1.8rem;
}

.network__Label {
  color: $color-gray-80;
  font-family: Open Sans, helvetica, sans-serif;
  font-size: 1.4rem;
  margin-top: $spacing-md;
}

.Overlay__Top--Border {
  background-color: $color-orange-60;
  border-top-left-radius: $fn-border-radius-sm;
  border-top-right-radius: $fn-border-radius-sm;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Overlay__Container {
  display: flex;
  flex-direction: column;
  margin: $spacing-lg;
  max-width: 25rem;
}

.Overlay__Content {
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing-md;
  padding-top: $spacing-sm;
}

.Overlay__Footer {
  background-color: $color-gray-10;
  border-bottom-left-radius: $fn-border-radius-sm;
  border-bottom-right-radius: $fn-border-radius-sm;
  display: flex;
  margin: -$spacing-lg;
  margin-top: 0;
  padding: $spacing-md;
}

.Overlay__Content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Overlay__Content li {
  align-items: center;
  display: flex;
}

.Label__bullet {
  background-color: $color-orange-60;
  border-radius: 3px;
  height: 6px;
  margin-right: $spacing-sm;
  margin-top: 1px;
  width: 6px;
}
