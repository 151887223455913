@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.Body--title {
  color: $color-gray-80;
  font-size: 18px;
  line-height: 22px;
}

.Body--header {
  color: rgba(0,0,0,.6);
  font-size: 26px;
  letter-spacing: -.2px;
  text-align: center;
}

.Body--resend-text {
  line-height: 20px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 920px) {
  .inline-container {
    margin: auto;
    width: 90%;
  }
}

@media only screen and (min-width: 921px) and (max-width: 1200px) {
  .inline-container {
    margin: auto;
    width: 70%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1450px) {
  .inline-container {
    margin: auto;
    width: 50%;
  }
}

@media only screen and (min-width: 1451px) {
  .inline-container {
    margin: auto;
    width: 40%;
  }
}
