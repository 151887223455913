@import '../../scss/helpers/_all.scss';

.chip--container {
  background-color: $color-green-20;
  display: inline-block;
  margin-top: $spacing-sm;
  padding: $spacing-2-xs $spacing-sm;
}

.icon--style {
  color: $color-green-60;
}

.chip--label {
  padding-left: $spacing-xs;
  padding-right: $spacing-xs;
}
