@import '../../../../scss/helpers/_all.scss';
@import '../../../Field-helpers';

@function reverse($spacing) {
  @return ($spacing * -1);
};

.modal-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: reverse($spacing-md);
  text-align: center;
  width: 100%;
}

.content-left {
  background-color: $color-gray-10;
  padding-bottom: $spacing-4-xl;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  padding-top: $spacing-4-xl;
  width: 40%;
}

.content-right {
  padding-bottom: $spacing-4-xl;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  padding-top: $spacing-4-xl;
  width: 60%;
}

.content-full {
  padding-bottom: $spacing-4-xl;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  padding-top: $spacing-4-xl;
  width: 50%;
}

.Message__icon {
  border-radius: $fn-border-radius-full;
  display: inline-block;
  padding-bottom: $spacing-lg;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
  padding-top: $spacing-xl;
}

.Message__basic {
  background-color: $color-blue-20;
  color: $color-blue-80;
}

.Message__plus {
  background-color: $color-teal-20;
  color: $color-teal-80;
}

.Message__premier {
  background-color: $color-indigo-20;
  color: $color-indigo-60;
}

.Success_true {
  background-color: $color-green-20;
  color: $color-green-60;
}

.Success_false {
  background-color: $color-red-20;
  color: $color-red-80;
}

.heading_1,
.heading_2 {
  padding-bottom: $spacing-lg;
  padding-top: $spacing-lg;
}

.heading_1 {
  font-size: $text-font-size-xxl;
}

.heading_2 {
  font-size: $text-font-size-lg;
}

.heading_2 span {
  font-family: $text-font-family-semi-bold;
}

.Common_spacing_y {
  padding-bottom: $spacing-sm;
  padding-top: $spacing-sm;
}

.Common_spacing_2y {
  padding-bottom: $spacing-md;
  padding-top: $spacing-md;
}

.Common_spacing_x {
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
}

.list {
  line-height: $text-line-height-lg;
  margin-left: 5%;
  text-align: left;
}

.list ul {
  list-style-type: none;
  padding-top: $spacing-xl;
}

.list a {
  padding-left: $spacing-xl;
}

.current-package-text {
  font-size: $text-font-size-sm
}
