@import '../../../../scss/helpers/_all.scss';
@import '../../../Field-helpers';

$container-width: 26.5rem;

.tooltip-heading {
  border-bottom: $border-width-sm solid $color-gray-50;
  font-size: $text-font-size-md;
  padding-bottom: $spacing-md;
}

.tooltip-content,
.tooltip-footer {
  padding-top: $spacing-md;
}
