@import '../../../../scss/helpers/all';

.section {
  &--create {
    &--single {
      margin-bottom: $spacing-xs;
      max-width: 350px;
    }

    &--multiple {
      margin-bottom: $spacing-sm;
      max-width: 350px;
    }
  }
}
