@import '../../../../scss/helpers/all';

.smart--address {
  display: block;
  margin-bottom: $spacing-md;
  margin-right: $spacing-3-xl;

  &--actionbar {
    &--double {
      margin-top: -($spacing-sm * 1.4);
    }

    &--simple {
      margin-top: 0;
    }
  }
}
