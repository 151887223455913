@import '../../../../scss/helpers/all';

.text--input {
  &--small {
    max-width: 13rem;
  }

  &--large {
    max-width: 50rem;
  }
}
