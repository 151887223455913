/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
.lcForm {
  background: #edeff3;
  border-radius: .5rem;
  margin: 0 0 .8rem 0;
  padding: .8rem;
}

.fileUpload {
  color: #555;
  font-size: '1.4rem';
  font-weight: 'bold';
}
