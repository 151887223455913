@import '../../../../scss/helpers/all';

@function reverse($spacing) {
  @return ($spacing * -1);
}

@mixin reverse-margin {
  margin: reverse($spacing-md);
}

.wrapper {
  @include reverse-margin;

  &--item {
    padding: $spacing-lg;
  }
}
