@import '../../../../scss/helpers/_all.scss';

.password-protected-request-content {
  width: 100%;

  .confirmation-text {
    padding-bottom: $spacing-md;
  }

  .confirmation-help-text {
    color: $color-red-100;
    margin-top: $spacing-sm;
  }

  .password-submit {
    padding-top: $spacing-md;
  }
}
