/* stylelint-disable  declaration-property-unit-blacklist */
/* stylelint-disable  declaration-property-value-blacklist */
.header-container {
  align-items: center;
  background: white;
  display: flex;
  margin: -1.6rem -1.6rem 0;
  padding: 1.6rem;
}

.NegativeBorderMargins {
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}

.InnerTabs {
  margin: 1.6rem;
  margin-bottom: 0;
}

.SkillsSectionContainer {
  border-top: 1px solid #c6ccd5;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  margin-top: 1.6rem;
  padding: 1.6rem;
}

.ErrorContentContainer {
  align-items: center;
  background-color: #fde8ea;
  border-radius: .4rem;
  color: #162741;
  display: flex;
  margin-top: 1.6rem;
  padding: 1.6rem;
}

.ErrorContentContainerIcon {
  color: #f25a68;
}

.PaginationContainer {
  border-top: 1px solid #c6ccd5;
  display: flex;
  justify-content: space-between;
}

.TableContainer {
  border-left: .1rem solid rgba(0,0,0,.12);
  border-right: .1rem solid rgba(0,0,0,.12);
}

.SkillSection {
  font-size: 1.7rem;
  margin-bottom: 1.2rem;
  margin-top: 0;
}

.SkillCategory {
  background: #edeff3;
  color: #747577;
  font-family: Open Sans SemiBold, helvetica, sans-serif;
  font-size: 1.2rem;
  padding: .6rem 1.6rem;
  text-transform: uppercase;
}

.SubTitle {
  color: #747577;
  display: inline;
}

.SkillCategory.highlighted {
  background: transparent;
  padding-left: 0;
}

.no-skills-text {
  margin-left: 3.2rem;
  margin-right: 1.5rem;
  text-align: center;
}

.SkillSubCategory,
.Skill .InnerSkill {
  height: 6rem;
}

.SkillSubCategory {
  align-items: center;
  background: #f8f9fc;
  display: flex;
  padding: .6rem 1.6rem;
}

.SkillSubCategory b,
.Skill b {
  font-family: Open Sans SemiBold, helvetica, sans-serif;
  font-weight: 300;
}

.SkillSubCategory.collapsed {
  border-bottom: 2px solid #e2e5ea;
}

.SkillSubCategory > div:first-child {
  margin-right: auto;
}

.Accordion {
  text-align: right;
  width: 3rem;
}

.Skill {
  border: 1.2px solid #c4cad4;
  border-radius: 4px;
  padding: 1.2rem 1.6rem;
}

.SkillOuter {
  border-top: 1px solid #e2e5ea;
  height: initial;
  margin-top: 1rem;
  padding-top: 1rem;
}

.SkillWrapper {
  display: flex;
  flex-direction: column;
}

.Padded {
  margin-top: 1rem;
}

.SkillTitleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.SkillTitle {
  flex: 1;
}

.Skill > .SkillOuter:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.Scrollable {
  max-height: 55vh;
  overflow-y: auto;
}

.LoadingSkills {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  height: 20rem;
  margin: 2rem;
  padding: 5rem;
}
