/* stylelint-disable  declaration-property-unit-disallowed-list */
/* stylelint-disable  declaration-property-unit-blacklist */

@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.Card__Outer {
  float: left;
  min-width: 20rem;
  padding: .5rem;
  width: 25%;
}

.Card__Container {
  background: $color-white;
  border: $border-width-sm $color-gray-50 solid;
  border-radius: $fn-border-radius-sm;
}

.Card__section {
  align-items: center;
  display: flex;
  height: 6rem;
  padding: 0 $fn-border-radius-md 0 $fn-border-radius-md;
}

.Card__Header {
  align-items: center;
  display: flex;
  height: 8rem;
  padding: 0 $fn-border-radius-md 0 $fn-border-radius-md;
}

.Card__Recommended {
  background: $color-green-20;
  height: 4rem;
}

.Card__Recommended__Line {
  background: $color-green-80;
  border-top-left-radius: $fn-border-radius-sm;
  border-top-right-radius: $fn-border-radius-sm;
  height: .6rem;
  margin-top: -.1rem
}

.Card__Recommended__text {
  align-items: center;
  display: flex;
  margin: .5rem 1.6rem;
}

.Card__Footer {
  align-items: center;
  background-color: $color-gray-10;
  border-bottom-left-radius: $fn-border-radius-sm;
  border-bottom-right-radius: $fn-border-radius-sm;
  display: flex;
  height: 8rem;
}
