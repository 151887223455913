@import '../../scss/helpers/_all.scss';
@import '../_Field-helpers';

.editor-wrapper {
  background-color: $color-gray-10;
  border: 1px solid rgb(220, 220, 220);
  border-radius: $spacing-xs;
  margin-bottom: $spacing-sm;
  max-width: 450px;
  min-width: 200px;
}

.slate-editor {
  max-height: 220px;
  min-height: 150px;
  overflow: scroll;
  padding: $spacing-sm;
}

.toolbar {
  align-items: center;
  border-bottom: 1px solid rgb(220, 220, 220);
  display: flex;
  padding: $spacing-xs;
  position: relative;
}

.toolbar-button {
  border-radius: $spacing-xs;
  cursor: pointer;
  margin-right: $spacing-xs;
  padding: $spacing-xs $spacing-xs 0;
}

.toolbar-button--active {
  background-color: rgba(0, 0, 0, .1);
}

.toolbar-button:hover {
  background-color: rgba(0, 0, 0, .1);
}
