@import '../../scss/helpers/_all.scss';
@import '../Field-helpers';

.OptionLabel {
  color: $color-gray-100;
  font-weight: $text-font-family-semi-bold;
}

.OptionLabelDisabled {
  color: $color-gray-60;
  font-weight: $text-font-family-semi-bold;
}

.InputPadding {
  padding-left: $spacing-md;
}

.InfoSpacing {
  margin-left: $spacing-sm;
}

.HeadingPadding {
  padding-left: $spacing-md;
}

.HeadingLineHeight {
  line-height: $text-line-height-md;
}

.DescriptionLineHeight {
  line-height: $text-line-height-sm;
}

.MainHeadingMargin {
  margin-bottom: $spacing-md;
}

.ModalMainHeadingMargin {
  margin-bottom: $spacing-sm;
}

.MainHeading {
  color: $color-gray-100;
  font-size: $font-size-h4;
}

.SubHeading {
  color: $color-gray-100;
  font-size: $text-font-size-sm;
}

.RadioOptions {
  margin: $spacing-md 0;
}

.RadioOptions label {
  margin: $spacing-xs;
}

.UbiNumberInput {
  margin-bottom: $spacing-sm;
}

.RadioContainer {
  display: flex;
  flex-direction: row;
}

.UbiNumberInputStyling input {
  margin-top: $spacing-md;
  max-width: 230px;
  min-width: 211px;
}

.DisabledLabel {
  color: $color-gray-70;
}

.FormSection {
  padding-left: 0;
}

.ActionButton {
  margin-top: $spacing-md;
}

.ErrorMessageStyle {
  color: $color-red-100;
}

.ErrorMessageDisplayNone {
  display: none;
}

.VerifiedUbi {
  color: $color-green-80;
}

.VerificationText {
  padding-left: $spacing-xs;
  vertical-align: top;
}

.InvalidUbi {
  color: $color-red-80;
}

.FlexContainer {
  display: flex;
  justify-content: flex-start;
}

.StatusInput {
  max-width: 180px;
  min-width: 150px;
  padding-left: $spacing-md;
}

.StatusInput label {
  margin-bottom: 22px; /* stylelint-disable-line declaration-property-unit-blacklist */
}
