@import '../../../../scss/helpers/all';

.section {
  &--empty {
    color: $color-gray-80;
    font-style: italic;
  }

  &--link {
    font-size: $text-font-size-xs;
  }

  &--access {
    color: $color-gray-70;
  }
}
