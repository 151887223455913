@import '../../../../scss/helpers/_all';

@function reverse($spacing) {
  @return ($spacing * -1);
}

.edit {
  &--disabled--content {
    padding-right: $spacing-md;
    padding-top: $spacing-md;
  }

  &--button {
    color: $color-gray-70;
    display: flex;
    justify-content: flex-end;
    margin-bottom: reverse($spacing-md);
    margin-top: reverse($spacing-md);
    padding-top: 2px; /* stylelint-disable-line declaration-property-unit-blacklist */

    &--icon {
      border-radius: 50%;
      color: $color-gray-70;
      height: 50px; /* stylelint-disable-line declaration-property-unit-blacklist */
      width: 50px; /* stylelint-disable-line declaration-property-unit-blacklist */

      span {
        margin-left: -(calc($spacing-2-xs / 2));
      }
    }
  }
}
