@import '../../../scss/helpers/_all.scss';
@import '../../_Field-helpers';

.container {
  width: 100%;
}

.label {
  color: $color-gray-80;
  display: block;
  font-family: $text-font-family-semi-bold;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: $spacing-2-xs;
}

.textarea {
  background-color: $color-white;
  border: 1px solid $color-gray-60;
  border-radius: $text-letter-spacing-4;
  max-height: 80px;
  min-height: 60px;
  padding: $spacing-xs;
  resize: vertical;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%;

  &:focus {
    border-color: $color-indigo-60;
    box-shadow: 0 0 0 1px $color-indigo-60;
    outline: none;
  }
}

.helperText {
  color: $color-gray-80;
  font-size: 12px;
  margin-top: $spacing-2-xs;
}

.counter {
  font-size: 12px;
  margin-top: $spacing-2-xs;
}

.warning {
  color: $color-yellow-60;
}

.error {
  color: $color-red-80;
}
