@import '../../../../scss/helpers/_all';

$container-max-width: 1100px;

.personal-info-component {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width !important; // stylelint-disable-line

  .list-item-name {
    color: $color-gray-90;
  }

  .list-item-content {
    color: $color-gray-100;

    &-empty {
      color: $color-gray-80;
      font-style: italic;
    }
  }

  .address-edit-form {
    margin-right: $spacing-3-xl;
  }

  @media only screen and (max-width: 900px) {
    .address-edit-form {
      margin-right: 0;
    }
  }

  .list-item-name-first {
    padding-top: 1.2rem; // stylelint-disable-line
  }

  .list-item-name-second {
    padding-top: $spacing-md;
  }

  .hide-number-type-arrow {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none; // stylelint-disable-line
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield; // stylelint-disable-line
    }
  }

  .email-verification-status {
    color: $color-gray-70;
    font-size: $text-font-size-xs;
  }
}

.connected-account-component {
  margin-top: 24px; // stylelint-disable-line
  margin-left: auto; // stylelint-disable-line
  margin-right: auto;
  max-width: $container-max-width;

  .list-item-name {
    color: $color-gray-80;
  }

  .list-item-action {
    color: $color-gray-70;
  }

  .list-item-content {
    color: $color-gray-100;

    &-empty {
      color: $color-gray-80;
      font-style: italic;
    }
  }
}
