@import '../../../../scss/helpers/_all.scss';
@import '../../../Field-helpers';

.tilemanager-main {
  padding-top: $spacing-md;
}

.card-cell {
  height: 100%;
}
