@import '../../../scss/helpers/_all.scss';
@import '../../_Field-helpers';

.container {
  width: 100%;
}

.trackingFields {
  align-items: flex-start;
  display: flex;
  gap: 1rem;
}

.trackingInput {
  flex: 2;
}

.carrierInput {
  flex: 1;
}

.carrierInfo {
  align-items: center;
  color: $color-gray-80;
  display: flex;
  font-size: $spacing-sm;
  font-size: 12px;
  font-weight: 400;
  gap: $spacing-2-xs;
  margin-top: $spacing-2-xs;
}

.carrierName {
  color: $color-gray-80;
  font-size: 12px;
  font-weight: 600;
}

.trackLink {
  align-items: center;
  color: $color-indigo-60;
  display: inline-flex;
  gap: $spacing-2-xs;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  svg {
    position: relative;
    top: 1px;
  }
}
